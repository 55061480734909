<template>
  <div>
    <div class="m-2">
      <b-button variant="primary" v-b-modal.export-csv pill>Export CSV</b-button>
    </div>

    <b-modal id="export-csv" title="Export to CSV" hide-footer>
      <FormulateForm v-model="formValues" @submit="addCompany">
        <div class="row">
          <div class="col-md-12 pt-2">
            <label class="typo__label" for="ajax">Field Name</label>
            <multiselect v-model="selectedFields" placeholder="Type to search" open-direction="bottom" :multiple="true"
              :searchable="true" :options="companies" :internal-search="true" :clear-on-select="true"
              :close-on-select="true" :max-height="600" :show-no-results="true" :hide-selected="true"
              @search-change="findCompany">
            </multiselect>
          </div>

        </div>

        <div style="text-align: end" class="mt-4">
          <FormulateInput type="submit" :label="submitting ? 'Downloading...' : 'Download'" />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { projects } from "@/config/api/projects";
import { API_ENDPOINT } from "@/config/axios";
import Multiselect from "vue-multiselect";
import { getFormData } from "@/utils/createForm";
import Swal from "sweetalert2";
export default {
  created() {
    this.loadCompanies();
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      formValues: {},
      selectedFields: ["Project Name",
        "Name English",
        "Name Arabic",
        "BCC", "Number of floors", "Project Type", "Latitude", "Longitude", "Verified", "Approved", "Status", "Country", "Location English", "Location Arabic",
        "Created By"],
      file: undefined,
      submitting: false,
      companies: [
        "Project Name",
        "Name English",
        "Name Arabic",
        "BCC", "Number of floors", "Project Type", "Latitude", "Longitude", "Verified", "Approved", "Status", "Country", "Location English", "Location Arabic",
        "Created By"
      ],
    };
  },
  methods: {
    findCompany(val) {
      this.loadCompanies(val);
    },

    addCompany() {
 

      const api = projects.exportToCsv;
      api.params = {fields:this.selectedFields};
      this.submitting = true;
      this.generateAPI(api)
        .then((res) => {
          const blob = new Blob([res.data], { type: 'application/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "project.csv"
          link.click()
          URL.revokeObjectURL(link.href)
          console.log(res.data);
          this.$bvModal.hide("export-csv");
          Swal.fire("Downloaded", "Downloaded successfully.", "success");
        })
        .catch((err) => {
          Swal.fire(
            "Failed",
            `Failed to download the file, ${err.response.data.message}`,
            "error"
          );
        })
        .finally(() => {
          this.submitting = false;
        });


    },
  },
};
</script>
