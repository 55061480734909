<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ExportCSV from "@/components/projects/export-csv";
import ProjectStatus from "@/components/projects/project-status.vue";
import { projects } from "@/config/api/projects";
import { property_types } from "@/config/api/property_types";
/**
 * Project Component
 */
export default {
  components: {
    Layout,
    PageHeader, ExportCSV, ProjectStatus
  },
  data() {
    return {
      title: "Projects",
      items: [
        {
          text: "All",
        },
        {
          text: "Projects",
          active: true,
        },
      ],
      selectedType: null,
      isBusy: false,
      ordersData: [],
      property_types_loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      property_types: [],
      filterOn: [],
      sortBy: undefined,
      sortDesc: undefined,
      fields: [
        { key: "ac_status", sortable: false, label: "Status" },
        { key: "name_en", sortable: true, label: "Name" },
        { key: "project_id", sortable: false, label: "Project Number" },
        { key: "project_type", sortable: false, label: "Type" },
        { key: "status_code", sortable: false, label: "Project Status" },
        { key: "createdAt", sortable: true, label: "Added Date" },
        { key: "created_by", sortable: false, label: "Created By" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },
  },
  watch: {
    sortBy: function () {
      this.loadData();
    },
    sortDesc: function (newVal) {
      console.log(newVal)
      this.loadData();
    },
    filter: function (newVal) {
      if (newVal.length > 2 || newVal.length === 0) {
        setTimeout(() => {
          this.loadData(1, this.filter);
        }, 2000);
      }
    },
    selectedType() {

      this.loadData();

    },
  },
  mounted() {
    // Set the initial number of items
    this.loadData();
    this.loadProjectType();
  },
  methods: {
    goToUsersPage(createdBy) {

      this.$router.push({
        path: '/user-info',
        query: { id: createdBy },
      })
    },
    /**
     * Search the table data with search input
     */
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      // this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage(page = 1) {
      this.loadData(page, null, this.perPage);
    },
    updatePageLimit() {
      this.loadData(1, null, this.perPage);
    },
    loadData(page = 1, search = "") {
      this.isBusy = true;
      let sort = {};
      if (this.sortBy) {
        sort[this.sortBy] = this.sortDesc ? -1 : 1
      }
      const api = projects.model.get;
      let params = {
        page: page,
        search: search,
        limit: this.perPage,
        ...sort
      };
      if (this.selectedType) {
        params.project_type = this.selectedType;
      }
      api.params = params;
      this.generateAPI(api).then((res) => {
        this.ordersData = res.data.projects.docs;
        this.totalRows = res.data.projects.totalDocs;
        this.isBusy = false;

      });
    },
    onRowClicked(item) {
      this.$router.push({
        path: "/project-info",
        query: { id: item.project_id },
      });
    },
    loadProjectType() {
      const api = property_types.getAllWithoutPagination;
      this.property_types_loading = true;
      this.generateAPI(api)
        .then((res) => {
          let data = res.data.property_types.map((item) => {
            return {
              value: item._id,
              text: item.name,
            };
          });
          data.push({ value: null, text: "View all" });
          this.property_types = data;
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          this.property_types_loading = false;
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <div class="d-flex">
              <b-form-select v-model="selectedType" :options="property_types" style="width: 280px" class="mt-2">
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="null" disabled>
                    <span v-if="property_types_loading">Loading Please wait (For filter)</span>
                    <span v-else>-- Filter Based on Type --</span>
                  </b-form-select-option>
                </template>
              </b-form-select>
              <ExportCSV></ExportCSV>
              <div class="m-2">
                <b-button id="addProject" @click="
                  $router.push({
                    path: `add-projects`,
                  })
                " pill variant="primary">Add Project</b-button>
              </div>
            </div>

            <b-tabs nav-class="nav-tabs-custom ">
              <b-tab title-link-class="p-3 mt-1">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Projects</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @change="updatePageLimit">
                        </b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2">
                        </b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table tbody-tr-class="rowClass" class="table-centered" :items="ordersData" :fields="fields"
                    responsive="sm" :busy="isBusy" :current-page="currentPage" :sort-by.sync="sortBy" :filter="filter"
                    :filter-included-fields="filterOn" :no-local-sorting=true @filtered="onFiltered"
                    :sort-desc.sync="sortDesc" @row-clicked="onRowClicked" show-empty>
                    <template #table-busy>
                      <div style="text-align: center" class="mt-5 mb-5">
                        <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                      </div>
                    </template>

                    <template v-slot:cell(paymentstatus)="row">
                      <div class="badge font-size-12" :class="{
                        'badge-soft-danger': `${row.value}` === 'Chargeback',
                        'badge-soft-success': `${row.value}` === 'Paid',
                        'badge-soft-warning': `${row.value}` === 'Unpaid',
                      }">
                        {{  row.value  }}
                      </div>
                    </template>
                    <template v-slot:cell(ac_status)="row">
                      <ProjectStatus :projectId="row.item.project_id" :isApproved="row.item.is_approved"></ProjectStatus>
                    </template>
                    <template v-slot:cell(invoice)>
                      <button class="btn btn-light btn-rounded">
                        Invoice
                        <i class="mdi mdi-download ml-2"></i>
                      </button>
                    </template>
                    <template v-slot:cell(status_code)="row">
                      {{  row.item.status_code.name  }}
                    </template>
                    <template v-slot:cell(project_type)="row">
                      Type: {{  row.value.name  }} <br>
                      <div v-if="row.item.sub_project_type">
                        SubType: {{  row.item.sub_project_type.name  }}
                      </div>
                    </template>
                    <template v-slot:cell(createdAt)="row">
                      {{  moment(row.value).format("LLL")  }}
                    </template>
                    <template v-slot:cell(created_by)="row">
                      <div v-if="row.item.createdBy">
                        <button class="no-bg-btn" @click="goToUsersPage(row.item.createdBy._id)">
                          {{  row.item.createdBy.name  }}
                        </button>
                      </div>
                      <div v-else>
                        User not found/ Deleted
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <router-link :to="{
                        path: '/project-info',
                        query: { id: row.item.project_id },
                      }">
                        <i class="ri-eye-line font-size-18"></i>
                      </router-link>

                      <router-link :to="{
                        path: '/edit-projects',
                        query: { id: row.item.project_id },
                      }">
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </router-link>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      ">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="updatePage">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
#addProject {
  float: right;
}


</style>